import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0057 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="働き方改革推進支援助成金（適用猶予業種等対応コース）、活用すべき事業主や対象となる取り組みは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.08.29</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働基準法では、2024年４月１日より、建設業、運送業、医師等の「適用猶予業種等」に対しても時間外労働の上限規制が適用されることとなります。そこで、今回取り上げる内容では、生産性を向上させ、かつ、時間外労働の削減、週休２日制の推進や勤務間インターバル制度の導入、医師の働き方改革推進に向け、環境整備に取り組む中小企業事業主を支援する働き方改革推進支援助成金（適用猶予業種等対応コース）という制度があります。{' '}
      </p>
      <img src="/images/column/details/c0057.jpg" alt="キンクラコラム画像57" />
      <h1 id="toc1">対象となる事業主とは</h1>
      <p>
        労災保険の適用事業主であり、交付申請時点において、後述する
        <span>「成果目標」1～4点の設定に向けた条件を満たしている</span>こと、
        <span>
          年5日の年次有給休暇の取得に向け就業規則を整備している中小事業主
        </span>
        であることです。
      </p>

      <h1 id="toc2">成果目標とは</h1>
      <p>
        1点目は全ての対象事業場において、令和５年度または令和６年度内において締結した（する）36協定において、時間外・休日労働時間数を縮減し、月に60時間以下、または月に60時間を超え月に80時間以下に上限を設定し、所轄労働基準監督署長に届出を行うことです。あるいは、全ての対象事業場において、令和５年度または令和６年度内において締結した（する）36協定について、時間外・休日労働時間数を縮減し、月に80時間以下に上限を設定し、所轄労働基準監督署長に届出を行うことです。
        <br />
        <br />
        2点目は全ての対象事業場において、「４週５休から４週８休以上」の範囲で所定休日を増加させることです。
        <br />
        <br />
        3点目は全ての対象事業場において、「９時間以上の勤務間インターバル制度」の規定を「新たに導入する」ことです。
        <br />
        <br />
        ４点目は医師の働き方改革推進に関する取組として以下を全て実施することで、以下の内容です。
        <br />
        ・労務管理体制の構築等
        <br />
        具体的には労務管理責任者を設置し、責任の所在とその役割を明確にすることや医師の副業・兼業先との労働時間の通算や医師の休息時間確保に係る協力体制の整備、管理者層に対し、
        <span>人事労務管理のマネジメント研修を実施する</span>
        ことが挙げられます。
        <br />
        ・医師の労働時間の実態把握と管理
        <br />
        具体的には労働時間と労働時間ではない時間の区別を明確化した上で、医師の労働時間の実態把握を行うこと、医師の勤務計画を作成することが挙げられます。
        <br />
        そして、
        <span>
          前述の成果目標に加え、対象事業場で指定する労働者の「時間当たりの賃金額の引上げを３％以上」行うことを成果目標に加える
        </span>
        ことができます。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">事業実施期間と締切</h1>
      <p>
        <span>
          交付決定の日から2024年1月31日まで（執筆時点）に取り組みを実施する
        </span>
        必要があります。また、
        <span>申請の締め切りについては2023年11月30日まで</span>
        となりますが、申し込み状況や予算額にも制約があるためにこの日よりも早く締め切られる場合もありますので、申請を検討する場合は注意が必要です。また、本助成金は事業所の所在地を管轄する都道府県労働局の雇用環境均等部（室）となり、窓口持参もしくは郵送での受付も可能です。
      </p>

      <h1 id="toc4">対象となる取り組みとは</h1>
      <p>
        ・労務管理担当者に向けた研修
        <br />
        ・労働者に対する研修や周知、啓発
        <br />
        ・外部専門家（例えば社会保険労務士、中小企業診断士）
        によるコンサルティング
        <br />
        ・就業規則等の作成や変更
        <br />
        ・人材確保に向けた取組
        <br />
        ・労務管理用ソフトウェアの導入や更新
        <br />
        ・労務管理用機器の導入や更新
        <br />
        ・デジタル式運行記録計（デジタコ）の導入や更新
        <br />
        ・労働能率の増進に資する設備等の導入や更新
      </p>

      <h1 id="toc5">助成額</h1>
      <p>
        取り組み実施にかかった経費の一部を成果目標の達成状況に応じて助成され、具体的な
        <span>助成額は以下のいずれか低い方の額</span>となります。
        <br />
        ・成果目標１から4の上限額および賃金加算額の合計額
        <br />
        ・対象経費の合計額×補助率4分の3
        <br />
        <br />
        なお、下段の取り組みについては、常時使用する労働者数が30人以下かつ、支給対象の取組において以下の取り組みを実施する場合で所要額が30万円を超える場合の補助率は5分の4となります。
        <br />
        ・労務管理用ソフトウェアの導入や更新
        <br />
        ・労務管理用機器の導入や更新
        <br />
        ・デジタル式運行記録計（デジタコ）の導入や更新
        <br />
        ・労働能率の増進に資する設備等の導入や更新
      </p>

      <h1 id="toc6">どのような課題を持つ事業主が活用すべきか？</h1>
      <p>
        適用業種であり、かつ、改正労働基準法を見据え、
        <span>
          労働時間の削減（業務効率化を目的とした機械化など）を目指す場合
        </span>
        、活用すべき助成金と言えます。特に医療業であれば慢性的な長時間労働が引き金となり、患者に対して危険が及ぶ可能性も否定できず、生産性が向上することによって労働者としても組織としても健康な状態で労務の提供と受領が可能となります。
        <br />
        <br />
        以下の図は建設業における活用事例です。
      </p>
      <img
        src="/images/column/details/c0057_g01.jpg"
        alt="キンクラコラム画像57"
      />
      <p>引用 厚生労働省</p>

      <h1 id="toc7">最後に</h1>
      <p>
        これまで業種の特殊性を勘案し、時間外労働の上限規制が猶予されていた業種も5年の猶予を経て上限規制が課されます。例えば運送業であっても医療業と同様に危険と隣り合わせで、労働時間が削減できることによる恩恵は小さくありません。無論、労働時間が長ければ長いほど、人件費も比例して高額化していくことは避けられず、今一度、要件を確認し、活用可能であれば、積極的に助成金を活用し、生産性の向上を進めていくことが有用です。
        <br />
        <br />
        また、助成金の要件は特に年度によって変更がありますので、その点も注意が必要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0057
